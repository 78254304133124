/* editorStyles.css */
.ql-editor {
  direction: ltr !important;
  text-align: left !important;
}

/* Ensure lists are aligned to the left */
.ql-editor ol,
.ql-editor ul {
  padding-left: 20px !important;
  text-align: left !important;
}

.ql-editor ol {
  list-style-position: inside !important;
}

.ql-editor ul {
  list-style-position: inside !important;
}

.snow-editor {
  background-color: white;
  border-radius: 0.375rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}

.snow-editor [contenteditable="true"]:empty:before {
  content: attr(placeholder);
  color: #6c757d;
  font-style: italic;
}

.snow-editor [contenteditable="true"] {
  outline: none;
  min-height: 200px;
  line-height: 1.5;
}

/* Dark mode styles */
.dark .snow-editor {
  background-color: #1e1e2d;
  border-color: #2f2f45;
}

.dark .snow-editor [contenteditable="true"]:empty:before {
  color: #8b949e;
}

/* We don't set a background color on editor content to prevent it from persisting */
.dark .snow-editor [contenteditable="true"] {
  /* No background-color here to prevent it from persisting in saved content */
  color: #e9ecef;
}

/* Make sure previewed content looks correct in dark mode */
.dark .tw-prose {
  color: #e9ecef;
}

.dark .tw-prose strong {
  color: #f8f9fa;
}

.dark .tw-prose h1,
.dark .tw-prose h2,
.dark .tw-prose h3 {
  color: #f8f9fa;
}

.dark .tw-prose ul,
.dark .tw-prose ol {
  color: #e9ecef;
}

.dark .tw-prose li {
  color: #e9ecef;
}

/* Selection color */
.snow-editor [contenteditable="true"]::selection {
  background: #d1d5db;
  color: inherit;
}

.dark .snow-editor [contenteditable="true"]::selection {
  background: #4b5563;
  color: inherit;
}

/* Link styles */
.snow-editor [contenteditable="true"] a {
  color: #2563eb;
  text-decoration: underline;
}

.dark .snow-editor [contenteditable="true"] a {
  color: #60a5fa;
}

/* List styles */
.snow-editor [contenteditable="true"] ul {
  list-style-type: disc;
  padding-left: 1.5em;
}

.snow-editor [contenteditable="true"] ol {
  list-style-type: decimal;
  padding-left: 1.5em;
}

/* Heading styles */
.snow-editor [contenteditable="true"] h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}

.snow-editor [contenteditable="true"] h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}

/* Paragraph spacing */
.snow-editor [contenteditable="true"] p {
  margin: 1em 0;
}

[data-bs-theme="dark"] .ql-toolbar {
  background-color: #2f2f45;
  border-color: #2f2f45 !important;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

[data-bs-theme="dark"] .ql-container {
  border-color: #2f2f45 !important;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

[data-bs-theme="dark"] .ql-editor.ql-blank::before {
  color: #6c757d;
}

[data-bs-theme="dark"] .ql-editor {
  color: #e9ecef;
}

[data-bs-theme="dark"] .ql-snow .ql-stroke {
  stroke: #6c757d;
}

[data-bs-theme="dark"] .ql-snow .ql-fill {
  fill: #6c757d;
}

[data-bs-theme="dark"] .ql-snow .ql-picker {
  color: #6c757d;
}

[data-bs-theme="dark"] .ql-snow .ql-picker-options {
  background-color: #2f2f45;
  border-color: #2f2f45;
}

[data-bs-theme="dark"] .ql-snow .ql-tooltip {
  background-color: #2f2f45;
  border-color: #2f2f45;
  color: #e9ecef;
}

[data-bs-theme="dark"] .ql-snow .ql-tooltip input[type="text"] {
  background-color: #1e1e2d;
  border-color: #2f2f45;
  color: #e9ecef;
}

/* Hover states for buttons */
[data-bs-theme="dark"] .ql-snow .ql-stroke:hover {
  stroke: #e9ecef;
}

[data-bs-theme="dark"] .ql-snow .ql-fill:hover {
  fill: #e9ecef;
}

[data-bs-theme="dark"] .ql-snow.ql-toolbar button:hover .ql-stroke {
  stroke: #e9ecef;
}

[data-bs-theme="dark"] .ql-snow.ql-toolbar button:hover .ql-fill {
  fill: #e9ecef;
}

[data-bs-theme="dark"] .ql-snow.ql-toolbar button.ql-active .ql-stroke {
  stroke: #e9ecef;
}

[data-bs-theme="dark"] .ql-snow.ql-toolbar button.ql-active .ql-fill {
  fill: #e9ecef;
}
