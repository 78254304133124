/* DataTable Animations */
.data-table {
  --transition-duration: 0.2s;
}

/* Fade in animation for new rows */
.fade-in {
  animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Filtering state animations */
.filtering tbody {
  opacity: 0.7;
  transition: opacity var(--transition-duration) ease-in-out;
}

/* Search icon spin animation */
.search-icon.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Sortable column headers */
.sortable {
  transition: background-color var(--transition-duration) ease-in-out;
}

.sortable:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.dark .sortable:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.sortable.active {
  background-color: rgba(0, 0, 0, 0.03);
}

.dark .sortable.active {
  background-color: rgba(255, 255, 255, 0.03);
}

/* Sort icon transitions */
.sortable i {
  transition: transform var(--transition-duration) ease-in-out;
}

.sortable:hover i {
  transform: scale(1.2);
}

/* Table row hover effect */
.data-table tbody tr {
  transition: background-color var(--transition-duration) ease-in-out;
}

.data-table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.dark .data-table tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.02);
}

/* Search input transitions */
.search-box input {
  transition: box-shadow var(--transition-duration) ease-in-out;
}

.search-box input:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.15);
}

/* Pagination transitions */
.pagination .page-link {
  transition: all var(--transition-duration) ease-in-out;
}

.pagination .page-link:hover {
  transform: translateY(-1px);
}

/* Empty state fade in */
.text-center.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}
