.property-unit-container {
  position: relative;
  min-height: calc(100vh - 200px);
  padding-bottom: 80px; /* Space for the sticky footer */
}

.property-unit-content {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  padding: 1rem;
}

.property-unit-content::-webkit-scrollbar {
  width: 8px;
}

.property-unit-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.property-unit-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.property-unit-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.unit-card {
  transition: all 0.3s ease;
}

.unit-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.unit-info-icon {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  margin-right: 8px;
}

.unit-actions {
  gap: 8px;
}

.unit-actions button {
  transition: all 0.2s ease;
}

.unit-actions button:hover {
  transform: scale(1.05);
}
